import { StaticImage } from "gatsby-plugin-image";
import * as React from "react";
import styled from "styled-components";

const Container = styled.div`
  width: 100%;
  margin: 1rem 0;
`;
const Control = styled.div`
  border-bottom: 1px solid #f1f2f4;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 0.7rem;
  cursor: pointer;
  .arrow-container {
    display: flex;
    transition: all 0.2s;
    position: relative;
    top: 4px;
    left: -8px;
  }
  &.closed {
    .arrow-container {
      transform: rotate(180deg);
    }
  }
  &.open {
    .arrow-container {
      transform: rotate(0);
    }
  }
`;
const Content = styled.div`
  max-height: 0;
  transition: max-height 0.15s ease-out;
  overflow: hidden;
  overflow: hidden;
  div {
    padding: 0.7rem 0;
    p {
      margin: 0 0 0.2rem;
      word-wrap: break-word;
    }
  }
  &.open {
    max-height: 500px;
    transition: max-height 0.25s ease-in;
  }
`;

export default function FAQItem({ title, content }) {
  const [isOpen, setIsOpen] = React.useState(false);

  return (
    <Container>
      <Control
        onClick={() => setIsOpen(!isOpen)}
        className={isOpen ? "open" : "closed"}
      >
        <span className="f-l-b">{title}</span>
        <span className="arrow-container">
          <StaticImage
            src="../images/Chevron.png"
            alt="Arrow"
            height={8}
            quality={100}
            placeholder="none"
          />
        </span>
      </Control>
      <Content className={isOpen ? "open" : "closed"}>
        <div>
          <p className="f-l-m">{content}</p>
        </div>
      </Content>
    </Container>
  );
}
