import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import styled from "styled-components";
import { StaticQuery, graphql } from "gatsby";

const Container = styled.div`
  position: relative;
  .anchor {
    width: 1px;
    height: 1px;
    position: absolute;
    top: 50%;
    left: 50%;
  }
  .star {
    position: absolute;
  }
`;

export default function Decor({ settings = [], children }) {
  return (
    <StaticQuery
      query={graphql`
        query DecorImageContainer {
          lineStar: file(name: { eq: "LineStar" }) {
            childImageSharp {
              gatsbyImageData(placeholder: NONE)
            }
          }
          solidStar: file(name: { eq: "SolidStar" }) {
            childImageSharp {
              gatsbyImageData(placeholder: NONE)
            }
          }
        }
      `}
      render={(data) => (
        <Container>
          <div className="anchor">
            {settings.map((d, i) => {
              return (
                <div
                  className="star"
                  key={i}
                  style={{
                    height: d.size,
                    width: d.size,
                    top: `${d.top}px`,
                    left: `${d.left}px`,
                  }}
                >
                  <GatsbyImage
                    alt={"text"}
                    image={getImage(data[d.image])}
                    objectFit="cover"
                    quality={100}
                  />
                </div>
              );
            })}
          </div>
          {children}
        </Container>
      )}
    />
  );
}
