import * as React from "react";
import Layout from "../components/layout";
import Block from "../components/block";
import styled from "styled-components";
import { BREAKPOINTS, CONTENT_WIDTH } from "../consts";
import { useMediaQuery } from "react-responsive";
import FAQItem from "../components/faq-item";
import { GatsbyImage, StaticImage, getImage } from "gatsby-plugin-image";
import Decor from "../components/decor";
import { Gradient, GradientWapper } from "../components/gradients";
import { graphql, Link } from "gatsby";
import { HOMEPAGE } from "../gradient-config";
import tippy from "tippy.js";

const formatPrice = (price) => {
  if (!price || isNaN(price)) {
    return null;
  }

  return Number(price).toLocaleString();
};

const nFormatter = (num, digits) => {
  const lookup = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "K" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "G" },
    { value: 1e12, symbol: "T" },
    { value: 1e15, symbol: "P" },
    { value: 1e18, symbol: "E" },
  ];

  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  const item = lookup
    .slice()
    .reverse()
    .find((item) => {
      return num >= item.value;
    });

  return item
    ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol
    : "0";
};

export const query = graphql`
  query SITE_INDEX_QUERY {
    allMarkdownRemark(
      limit: 3
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { published: { eq: true } } }
    ) {
      nodes {
        id
        fields {
          slug
        }
        frontmatter {
          title
          date(formatString: "LL")
          coverImage {
            childImageSharp {
              gatsbyImageData(placeholder: NONE)
            }
          }
        }
      }
    }
  }
`;

const OpenPDFInNewTab = () => {
  return (
    <>
      Open PDF in new tab
      <StaticImage
        src="../images/Arrow.png"
        alt="->"
        width={24}
        quality={100}
        placeholder="none"
      />
    </>
  );
};

const Page = styled.div``;
const HeroContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  min-height: 80vh;
  justify-content: center;
  h1 {
    max-width: 700px;
  }
  .kpis {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 3rem;
    opacity: 0;
    position: relative;
    top: -10px;
    transition: all 0.1s ease-in 0s;
    &.kpis-loaded {
      opacity: 1;
      top: 0px;
    }
    .kpi {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      .name {
        display: inline-block;
        margin-top: 0.2rem;
      }
    }
  }

  @media screen and (max-width: ${BREAKPOINTS["mobile"]}px) {
    width: 92%;
    margin-top: 2rem;
    .kpis {
      margin-top: 1.8rem;
      flex-wrap: wrap;
      .kpi {
        flex: 48%;
        margin-bottom: 1.4rem;
      }
    }
  }
`;

const AboutUsContainer = styled.div`
  text-align: center;
  .about-sub-title {
    max-width: ${CONTENT_WIDTH}rem;
    display: inline-block;
  }

  @media screen and (max-width: ${BREAKPOINTS["mobile"]}px) {
    text-align: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;
const AboutCards = styled.div`
  width: auto;
  display: inline-flex;
  justify-content: space-between;
  max-width: 37rem;
  margin: 1rem 0 6rem;

  @media screen and (max-width: ${BREAKPOINTS["mobile"]}px) {
    flex-direction: column;
  }
`;
const AboutCard = styled.div`
  border-radius: 1rem;
  padding: 1.6rem 3rem 1.7rem;
  background-color: rgb(206 231 241 / 7%);
  margin: 0 1rem;
  h3 {
    margin: 10px 0 0 0;
  }
  @media screen and (max-width: ${BREAKPOINTS["mobile"]}px) {
    margin: 0 0 1.2rem 0;
    &:last-child {
      margin-bottom: 0;
    }
  }
`;
const AboutUsBlock = styled.div`
  display: flex;
  margin: 0 0 5rem 0;
  align-items: center;
  max-width: ${CONTENT_WIDTH}rem;
  &:last-child {
    margin-bottom: 0;
  }
  div.text {
    text-align: left;
    p {
      margin: 0;
    }
  }
  div.side {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    &.chain-logo-container {
      flex-direction: row;
      padding-left: 4rem;
      min-width: 11rem;
      min-width: 11rem;
      > div {
        margin: 0 1rem;
      }
    }
    &.asset-logo-container {
      flex-direction: column;
      padding-left: 4rem;
      min-width: 11rem;
      padding-right: 4rem;
      min-width: 11rem;
      div.row {
        margin: 0.6rem 0;
        > div {
          margin: 0 0.6rem;
          &:first-child {
            margin-left: 0;
          }
          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
    &.current-prices-container {
      margin-left: 5rem;
      padding: 1.8rem;
      gap: 8px;
      background-color: rgba(206, 231, 241, 0.05);
      display: flex;
      flex-direction: column;
      backdrop-filter: blur(50px);
      border-radius: 40px;
      .row {
        min-width: 14rem;
        width: 100%;
        border-bottom: 1px solid #f1f2f4;
        display: flex;
        justify-content: space-between;
        padding-bottom: 8px;
        .name {
          display: flex;
          align-items: center;
          > div {
            margin-right: 0.4rem;
          }
        }
      }
      @media screen and (max-width: ${BREAKPOINTS["mobile"]}px) {
        margin-top: 3rem !important;
      }
    }
  }

  @media screen and (max-width: ${BREAKPOINTS["mobile"]}px) {
    flex-direction: column;
    justify-content: center;
    width: 92%;
    margin-bottom: 4rem;
    &.delegate {
      flex-direction: column-reverse;
    }
    div.text {
      text-align: center;
      p {
        margin: 0;
      }
    }
    div.side {
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 1;
      box-sizing: border-box;
      &.chain-logo-container {
        flex-direction: row;
        padding: 0;
        min-width: auto;
        margin-top: 2rem;
      }
      &.asset-logo-container {
        flex-direction: column;
        padding: 0;
        min-width: auto;
        margin-top: 2rem;
      }
      &.current-prices-container {
        width: 100%;
        margin: 2rem 0 0 0;
        padding: 1.2rem;
        .row {
          min-width: auto;
        }
      }
    }
  }
`;

const FAQContainer = styled.div`
  max-width: ${CONTENT_WIDTH}rem;
  width: 100%;
  @media screen and (max-width: ${BREAKPOINTS["mobile"]}px) {
    h2 {
      text-align: center;
    }
  }
`;

const KeyPointsContainer = styled.div`
  text-align: center;
  width: 100%;
  margin-bottom: 5rem;
`;
const KeyPoints = styled.div`
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: ${BREAKPOINTS["mobile"]}px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;
const KeyPoint = styled.div`
  max-width: 270px;
  .number {
    background: rgba(206, 231, 241, 0.05);
    backdrop-filter: blur(50px);
    border-radius: 100px;
    width: 80px;
    height: 80px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1rem;
  }
  @media screen and (max-width: ${BREAKPOINTS["mobile"]}px) {
    max-width: auto;
    margin-bottom: 2.5rem;
  }
  @media screen and (max-width: ${BREAKPOINTS["mobile"]}px) {
    .number {
      width: 64px;
      height: 64px;
    }
  }
`;

const EpochCycleContainer = styled.div`
  h2 {
    text-align: center;
  }
  @media screen and (max-width: ${BREAKPOINTS["mobile"]}px) {
  }
`;
const EpochCycleBoxes = styled.div`
  padding-top: 0.8rem;
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: ${BREAKPOINTS["mobile"]}px) {
    flex-direction: column;
  }
`;
const EpochCycleBox = styled.div`
  width: 48.5%;
  .hover-effect {
    display: flex;
    align-items: center;
    text-align: right;
    justify-content: end;
    margin: 0.3rem 0.25rem 0 0;
  }
  @media screen and (max-width: ${BREAKPOINTS["mobile"]}px) {
    width: 100%;
    margin-bottom: 2rem;
  }
`;

const ArticlesContainer = styled.div`
  h2 {
    text-align: center;
  }
`;
const ArticlesBoxes = styled.div`
  padding-top: 0.8rem;
  margin-bottom: 2rem;
  display: flex;
  @media screen and (max-width: ${BREAKPOINTS["mobile"]}px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;
const ArticlesBox = styled.div`
  padding: 1rem;
  width: 33%;
  > a {
    > span {
      display: block;
      &.date {
        padding-top: .5rem;
      }
    }
    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      padding-right: 0;
    }
    .title {
      color: #f1f2f4;
    }
  }
  @media screen and (max-width: ${BREAKPOINTS["mobile"]}px) {
    width: 94%;
    margin: 0 0 2rem 0;
    padding: 0;
  }
`;

const IndexPage = ({ data }) => {
  const [isMobile, setIsMobile] = React.useState(false);
  const [prices, setPrices] = React.useState({});
  const [heroData, setHeroData] = React.useState(null);
  const [gradients, setGradients] = React.useState({});

  const mediaQuery = useMediaQuery({
    query: `(max-width: ${BREAKPOINTS["mobile"]}px)`,
  });

  React.useEffect(() => {
    setIsMobile(mediaQuery);
    setGradients(mediaQuery ? HOMEPAGE.MOBILE : HOMEPAGE.DESKTOP);
  }, [mediaQuery, isMobile]);

  React.useEffect(() => {
    const TimedFunction = () => {
      fetch(`https://api.bifrostoracle.com/ftso-prices`)
        .then((response) => response.json())
        .then((json) => {
          const output = {};
          json.forEach((i) => {
            output[i.symbol] = formatPrice(i.price);
          });
          setPrices(output);
        })
        .catch(() => null);

      fetch(
        `https://flaremetrics.io/api/ftso/provider/metrics?network=songbird&address=0x69141e890f3a79cd2cff552c0b71508be23712dc&period=latest`
      )
        .then((response) => response.json())
        .then((json) => {
          const output = {
            availability: `${json["availability"]}%`,
            votePower: nFormatter(json["live_vote_power"]),
            rewardRate: formatPrice(json["average_reward_rate"]),
            currentFee: "20%",
          };
          setHeroData(output);
        })
        .catch(() => null);
    };
    TimedFunction();
    setInterval(TimedFunction, 120000);

    tippy("#app-button-hero", {
      content: "Coming Soon!",
      placement: "bottom",
      animation: "fade",
      theme: "basic",
    });
  }, []);

  return (
    <Page>
      <Layout>
        <GradientWapper>
          <Gradient gradient={gradients[1]} />
          <Block fullHeight={true}>
            <HeroContainer>
              <Decor
                settings={
                  isMobile
                    ? [
                        {
                          image: "lineStar",
                          size: 26,
                          top: 15,
                          left: 136,
                        },
                        {
                          image: "solidStar",
                          size: 26,
                          left: -125,
                          top: -94,
                        },
                      ]
                    : [
                        {
                          image: "lineStar",
                          size: 32,
                          left: 380,
                          top: 53,
                        },
                        {
                          image: "solidStar",
                          size: 32,
                          top: -134,
                          left: -417,
                        },
                      ]
                }
              >
                <h1 className={isMobile ? "f-3xl" : "f-5xl"}>
                  Delegation made easy with Bifrost Oracle
                </h1>
              </Decor>
              <p className={isMobile ? "f-m-m" : "f-l-m"}>
                Support the Flare and Songbird blockchains by delegating your vote power.
              </p>
              <button className="disabled large f-m-m" id="app-button-hero">
                Enter App
              </button>
              <div className={heroData ? "kpis kpis-loaded" : "kpis"}>
                <div className="kpi">
                  {heroData && heroData.votePower && (
                    <span className="count f-4xl">{heroData.votePower}</span>
                  )}
                  <span className="name f-l-m">Vote Power</span>
                </div>
                <div className="kpi">
                  {heroData && heroData.availability && (
                    <span className="count f-4xl">{heroData.availability}</span>
                  )}
                  <span className="name f-l-m">Availability</span>
                </div>
                <div className="kpi">
                  {heroData && heroData.currentFee && (
                    <span className="count f-4xl">{heroData.currentFee}</span>
                  )}
                  <span className="name f-l-m">Fee</span>
                </div>
                <div className="kpi">
                  {heroData && heroData.rewardRate && (
                    <span className="count f-4xl">{heroData.rewardRate}</span>
                  )}
                  <span className="name f-l-m">Reward Rate</span>
                </div>
              </div>
            </HeroContainer>
          </Block>
        </GradientWapper>

        <GradientWapper>
          <Gradient gradient={gradients[2]} />
          <Gradient gradient={gradients[3]} />
          <Block id="about">
            <AboutUsContainer>
              <div>
                <h2 className="f-3xl">About Bifrost Oracle</h2>
                <p className="f-l-m about-sub-title">
                  Bifrost Oracle is a blockchain data provider run by Towo Labs,
                  a Flare and Songbird Network development partner and the
                  company behind Bifrost Wallet.
                </p>
              </div>
              <AboutCards>
                <AboutCard>
                  <StaticImage
                    src="../images/Target.png"
                    alt="Accurate"
                    width={60}
                    quality={100}
                    placeholder="none"
                    className="about-card-icon"
                  />
                  <h3 className="f-xl-b">Accurate</h3>
                </AboutCard>
                <AboutCard>
                  <StaticImage
                    src="../images/DocumentSecure.png"
                    alt="Reliable"
                    width={60}
                    quality={100}
                    placeholder="none"
                    className="about-card-icon"
                  />
                  <h3 className="f-xl-b">Reliable</h3>
                </AboutCard>
                <AboutCard>
                  <StaticImage
                    src="../images/Handshake.png"
                    alt="Trusted"
                    width={60}
                    placeholder="none"
                    className="about-card-icon"
                  />
                  <h3 className="f-xl-b">Trusted</h3>
                </AboutCard>
              </AboutCards>
              <AboutUsBlock>
                <div className="text">
                  <h3 className="f-3xl">What can you delegate?</h3>
                  <p className="f-l-m">
                    You can delegate Flare (FLR) and Songbird (SGB), the native tokens of the Flare and Songbird blockchains.
                    Delegating your tokens does not involve any transfer of value, simply a transfer of your vote power.
                  </p>
                </div>
                <div className="side chain-logo-container">
                  <StaticImage
                    src="../images/Songbird-Logo.png"
                    alt="Songbird Logo"
                    width={60}
                    quality={100}
                    placeholder="none"
                  />
                  <StaticImage
                    src="../images/Flare-Logo.png"
                    alt="Flare Logo"
                    width={60}
                    quality={100}
                    placeholder="none"
                  />
                </div>
              </AboutUsBlock>
              {/* <AboutUsBlock className="delegate">
                <div className="side asset-logo-container">
                  <div className="row">
                    <StaticImage
                      src="../images/Coins/BTC.png"
                      alt="BTC Logo"
                      width={56}
                      quality={100}
                      placeholder="none"
                    />
                  </div>
                  <div className="row">
                    <StaticImage
                      src="../images/Coins/LTC.png"
                      alt="LTC Logo"
                      width={56}
                      quality={100}
                      placeholder="none"
                    />
                    <StaticImage
                      src="../images/Coins/XRP.png"
                      alt="XRP Logo"
                      width={56}
                      quality={100}
                      placeholder="none"
                    />
                    <StaticImage
                      src="../images/Coins/XLM.png"
                      alt="XLM Logo"
                      width={56}
                      quality={100}
                      placeholder="none"
                    />
                  </div>
                  <div className="row">
                    <StaticImage
                      src="../images/Coins/Algo.png"
                      alt="Algo Logo"
                      width={56}
                      quality={100}
                      placeholder="none"
                    />
                    <StaticImage
                      src="../images/Coins/Doge.png"
                      alt="Doge Logo"
                      width={56}
                      quality={100}
                      placeholder="none"
                    />
                  </div>
                </div>
                <div className="text">
                  <h5 className="f-3xl">Supported F/S-Assets</h5>
                  <p className="f-l-m">
                    Nec fringilla in eget morbi condimentum mi id. Nunc, laoreet
                    netus et viverra sit arcu. At neque ullamcorper fermentum
                    mauris massa consequat pellentesque lacinia congue.
                  </p>
                </div>
              </AboutUsBlock> */}
              <AboutUsBlock>
                <div className="text">
                  <Decor
                    settings={
                      isMobile
                        ? [
                            {
                              image: "lineStar",
                              size: 26,
                              left: -170,
                              top: 70,
                            },
                            {
                              image: "solidStar",
                              size: 26,
                              top: -110,
                              left: 130,
                            },
                          ]
                        : [
                            {
                              image: "lineStar",
                              size: 32,
                              left: -342,
                              top: 100,
                            },
                            {
                              image: "solidStar",
                              size: 32,
                              top: -130,
                              left: 81,
                            },
                          ]
                    }
                  >
                    <h3 className="f-3xl">Current FTSO Prices</h3>
                    <p className="f-l-m">
                      Live on-chain prices from the Flare Time Series Oracle system on the Songbird blockchain.
                    </p>
                  </Decor>
                </div>
                <div className="side current-prices-container">
                  <div className="row">
                    <span className="name f-l-m">
                      <StaticImage
                        src="../images/Coins/BTC.png"
                        alt="BTC"
                        width={24}
                        quality={100}
                        placeholder="none"
                      />
                      BTC/USD
                    </span>
                    <span className="value f-l-m">
                      {prices["BTC/USD"] ? prices["BTC/USD"] : "-"}
                    </span>
                  </div>
                  <div className="row">
                    <span className="name f-l-m">
                      <StaticImage
                        src="../images/Coins/LTC.png"
                        alt="LTC"
                        width={24}
                        quality={100}
                        placeholder="none"
                      />
                      LTC/USD
                    </span>
                    <span className="value f-l-m">
                      {prices["LTC/USD"] ? prices["LTC/USD"] : "-"}
                    </span>
                  </div>
                  <div className="row">
                    <span className="name f-l-m">
                      <StaticImage
                        src="../images/Coins/ADA.png"
                        alt="ADA"
                        width={24}
                        quality={100}
                        placeholder="none"
                      />
                      ADA/USD
                    </span>
                    <span className="value f-l-m">
                      {prices["ADA/USD"] ? prices["ADA/USD"] : "-"}
                    </span>
                  </div>
                  <div className="row">
                    <span className="name f-l-m">
                      <StaticImage
                        src="../images/Coins/XRP.png"
                        alt="XRP"
                        width={24}
                        quality={100}
                        placeholder="none"
                      />
                      XRP/USD
                    </span>
                    <span className="value f-l-m">
                      {prices["XRP/USD"] ? prices["XRP/USD"] : "-"}
                    </span>
                  </div>
                  <div className="row">
                    <span className="name f-l-m">
                      <StaticImage
                        src="../images/Coins/XLM.png"
                        alt="XLM"
                        width={24}
                        quality={100}
                        placeholder="none"
                      />
                      XLM/USD
                    </span>
                    <span className="value f-l-m">
                      {prices["XLM/USD"] ? prices["XLM/USD"] : "-"}
                    </span>
                  </div>
                  <div className="row">
                    <span className="name f-l-m">
                      <StaticImage
                        src="../images/Coins/Doge.png"
                        alt="Doge"
                        width={24}
                        quality={100}
                        placeholder="none"
                      />
                      DOGE/USD
                    </span>
                    <span className="value f-l-m">
                      {prices["DOGE/USD"] ? prices["DOGE/USD"] : "-"}
                    </span>
                  </div>
                  <div className="row">
                    <span className="name f-l-m">
                      <StaticImage
                        src="../images/Coins/Algo.png"
                        alt="Algo"
                        width={24}
                        quality={100}
                        placeholder="none"
                      />
                      ALGO/USD
                    </span>
                    <span className="value f-l-m">
                      {prices["ALGO/USD"] ? prices["ALGO/USD"] : "-"}
                    </span>
                  </div>
                </div>
              </AboutUsBlock>
            </AboutUsContainer>
          </Block>
        </GradientWapper>

        <GradientWapper>
          <Gradient gradient={gradients[4]} />
          <Block id="faq" hideOverflow>
            <FAQContainer>
              <h2 className="f-3xl">FAQ</h2>
              <FAQItem
                title={"What is the purpose of the FTSO system?"}
                content={
                  "Smart contracts on the Flare and Songbird blockchains are in need of accurate and reliable price date for various use-cases. The Flare Time Series Oracle (FTSO) is a decentralized system of signal providers feeding the blockchain with the requested price data."
                }
              ></FAQItem>
              <FAQItem
                title={"What is an FTSO provider?"}
                content={
                  "An FTSO provider is an individual, team or entity running a service that programmatically collects price data from various off-chain sources, such as cryptocurrency exchanges, processes it and submits it to the blockchain according to well-defined rules and protocols."
                }
              ></FAQItem>
              <FAQItem
                title={"How do I select a good provider?"}
                content={
                  "A good FTSO provider maintains excellent uptime, has a good reputation in the Flare and Songbird communities, collects price data from a large number of sources, operates independently and with high integrity. You can utilize comparison sites like flaremetrics.io to see statistics for various signal providers."
                }
              ></FAQItem>
              <FAQItem
                title={"When will I be rewarded?"}
                content={
                  "A reward epoch lasts 7 days on Songbird and 3.5 days on Flare. Assuming that you have delegated your vote power before the last reward epoch began, you'll be rewarded at the end of the reward epoch."
                }
              ></FAQItem>
              <FAQItem
                title={"Where does the rewards come from?"}
                content={
                  "The Flare and Songbird blockchains have an annually fixed inflation rate. Users delegating their vote power to well-performing FTSO providers are rewarded in the native tokens coming from this inflation."
                }
              ></FAQItem>
              <FAQItem
                title={"Why am I not getting any rewards?"}
                content={
                  "Your selected FTSO providers may be inactive or underperforming. Only FTSO providers continuously submitting accurate prices are rewarded. It may also be the case that you just recently started delegating your vote power and have to wait for the first reward epoch to finish."
                }
              ></FAQItem>
              <FAQItem
                title={"When do my rewards expire?"}
                content={
                  "Unclaimed rewards expire after 90 days. Make sure to claim your rewards after each reward epoch."
                }
              ></FAQItem>
              <FAQItem
                title={"What is Bifrost Oracle's Songbird address?"}
                content={
                  "Our FTSO provider address on Songbird Network is 0x69141e890f3a79cd2cff552c0b71508be23712dc."
                }
              ></FAQItem>
              <FAQItem
                title={"What is Bifrost Oracle's Flare address?"}
                content={
                  "Our FTSO provider address on Flare Network is 0x9a46864a3b0a7805b266c445289c3fad1e48f18e."
                }
              ></FAQItem>
              <FAQItem
                title={"What is Bifrost Claim Bot's address?"}
                content={
                  "Our executor's address on both Flare Network and Songbird Network is 0xdD76d702358936Af39c0E9F7E71F9512C83cacaE."
                }
              ></FAQItem>
            </FAQContainer>
          </Block>
        </GradientWapper>

        <Block id="epoch-cycles">
          <EpochCycleContainer>
            <h2 className="f-3xl">Epoch Cycles</h2>
            <EpochCycleBoxes>
              <EpochCycleBox>
                <StaticImage
                  src="../images/SongbirdNetwork.png"
                  alt="Songbird Epoch Cycle"
                  quality={100}
                  placeholder="none"
                  backgroundColor="rgba(206,231,241,0.05)"
                />
                <a
                  href="/epoch-cycle-songbird.pdf"
                  target="_blank"
                  className="f-m-m hover-effect link"
                >
                  <OpenPDFInNewTab />
                </a>
              </EpochCycleBox>
              <EpochCycleBox>
                <StaticImage
                  src="../images/FlareNetwork.png"
                  alt="Flare Epoch Cycle"
                  quality={100}
                  placeholder="none"
                  backgroundColor="rgba(206,231,241,0.05)"
                />
                <a
                  href="/epoch-cycle-flare.pdf"
                  target="_blank"
                  className="f-m-m hover-effect link"
                >
                  <OpenPDFInNewTab />
                </a>
              </EpochCycleBox>
            </EpochCycleBoxes>
          </EpochCycleContainer>
        </Block>

        <Block id="articles">
          <ArticlesContainer>
            <h2 className="f-3xl">Our Articles</h2>
            <ArticlesBoxes>
              {data?.allMarkdownRemark?.nodes.map((article, key) => {
                return (
                  <ArticlesBox key={key}>
                    <Link className="article-card" to={article.fields.slug}>
                      <GatsbyImage
                        image={{...getImage(article.frontmatter.coverImage), width: 870, height: 600}}
                        alt={article.frontmatter.title}
                        quality={100}
                        placeholder="none"
                        objectFit="cover"
                        imgStyle={{borderRadius: 25}}
                      />

                      <span className="date f-m-m">
                        {article.frontmatter.date}
                      </span>

                      <span className="title hover-effect f-l-b">
                        {article.frontmatter.title}
                      </span>
                    </Link>
                  </ArticlesBox>
                );
              })}
            </ArticlesBoxes>
            <div style={{ textAlign: "center" }}>
              <Link to="/blog/">
                <button className="f-m-b">View all</button>
              </Link>
            </div>
          </ArticlesContainer>
        </Block>

        <GradientWapper>
          <Gradient gradient={gradients[5]} />
          <Block id="key-points">
            <KeyPointsContainer>
              <h3 className="f-3xl">Step by Step</h3>
              <KeyPoints>
                <KeyPoint>
                  <div className="number f-3xl">1</div>
                  <div className="text f-l-m">
                    Wrap some Songbird or Flare to gain vote power using e.g. Bifrost Wallet
                  </div>
                </KeyPoint>
                <KeyPoint>
                  <div className="number f-3xl">2</div>
                  <div className="text f-l-m">
                    Find reliable FTSO signal providers to delegate your vote power to
                  </div>
                </KeyPoint>
                <KeyPoint>
                  <div className="number f-3xl">3</div>
                  <div className="text f-l-m">
                    Regularly check the performance of your signal providers and claim rewards
                  </div>
                </KeyPoint>
              </KeyPoints>
            </KeyPointsContainer>
          </Block>
        </GradientWapper>
      </Layout>
    </Page>
  );
};

export default IndexPage;
